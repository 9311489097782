$(document).ready(function(){
    $('.category').slick({
        slidesToShow: 4,
        arrows: false,
        dots: false,
        lazyLoad: 'ondemand',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    mobileFirst: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    autoplay: false,
                    mobileFirst: true,
                    slidesToShow: 2,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    mobileFirst: true,
                    autoplay: false,
                    slidesToShow: 1,
                    dots: true
                }
            }
        ]
    })
});
