$(document).ready(function(){
  $('.advantages-slider').slick({
    slidesToShow: 5,
    arrows: true,
    dots: false,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  })
});
