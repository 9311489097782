$(document).ready(function(){
    $(".top-slider").slick({
        slidesToShow: 1,
        infinite: true,
        dots: false,
        arrows: true,
        adaptiveHeight: false,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: false,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    })
})
