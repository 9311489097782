global.jquery = global.jQuery = global.$ = require('jquery');

// plugins

// import 'popper.js'
import '../bootstrap/bootstrap'
import 'jquery.maskedinput/src/jquery.maskedinput'
import 'slick-carousel/slick/slick'

// custom js
import './phone-mask'
import './body-with-header-padding'
import './top-slider'
import './mobile-menu'
import './advantages-slider'
import './_category-slider'


// import css file's
import '../scss/main.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


// fancybox
import '../vendor/fancybox/jquery.fancybox.min'
import '../vendor/fancybox/jquery.fancybox.min.css'
